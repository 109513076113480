import {Controller} from "@hotwired/stimulus"
import {Dropdown} from "bootstrap";
import {post, get} from "@rails/request.js";

export default class JobAppsChangeStageController extends Controller {
  static targets = ["dropdown", "dropdownItemButton", "spinner", "alert", "alertMessage"]
  static outlets = ["accounts--job-applications-table"]
  static values = {url: String, discardModalUrl: String}

  connect() {
    console.log(
      "JobAppsChangeStageController connected",
      this.dropdown,
    );


    // TODO: remove this
    // this.openDiscardModal( ['78', '73', '80']);
  }

  disconnect() {
  }

  changeStage(event) {
    console.log("changeStage", event.params, this.tableController.selectedApplications);

    this.disableDropdownItemButtons();
    this.showSpinner();

    const promise = event.params.stage === "rejected" ?
      this.openDiscardModal(this.tableController.selectedApplications) :
      this.saveChanges(event.params.stage, this.tableController.selectedApplications);

    promise.then(response => {
      this.enableDropdownItemButtons();
      this.hideSpinner();
      this.tableController.refreshTable();

      this.dropdown.hide();
    }).catch(error => {
      this.enableDropdownItemButtons();
      this.hideSpinner();
    });

  }

  get dropdown() {
    return Dropdown.getOrCreateInstance(this.dropdownTarget);
  }

  get tableController() {
    return this.accountsJobApplicationsTableOutlet;
  }

  showSpinner() {
    this.spinnerTarget.classList.remove("d-none");
  }

  hideSpinner() {
    this.spinnerTarget.classList.add("d-none");
  }

  disableDropdownItemButtons() {
    this.dropdownItemButtonTargets.forEach(button => button.setAttribute("disabled", "disabled"));
  }

  enableDropdownItemButtons() {
    this.dropdownItemButtonTargets.forEach(button => button.disabled = false);
  }

  openDiscardModal(selectedApplications) {
    console.log("openDiscardModal", selectedApplications);

    const urlParams = new URLSearchParams();

    for (const applicationId of selectedApplications) {
      urlParams.append("job_application_ids[]", applicationId);
    }

    return get(this.discardModalUrlValue, {
      query: urlParams,
      headers: {},
      responseKind: "turbo-stream"
    });
  }

  saveChanges(stage, selectedApplications) {
    console.log("saveChanges", selectedApplications, stage);

    return post(this.urlValue, {
      body: {
        job_application_ids: selectedApplications,
        stage
      },
      headers: {},
      responseKind: "turbo-stream"
    });
  }

}
