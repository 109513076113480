import {Controller} from "@hotwired/stimulus"
import {Toast} from "bootstrap";

export default class extends Controller {
  static values = {
    variant: {type: String, default: "info"},
    message: String
  }

  connect() {
    this.toastId = `toast-${Date.now().valueOf()}`;
    console.debug("Toast: connected", this.toastId);

    setTimeout(() => {
      const toastDocumentFragment = this.element.content.cloneNode(true);

      // Add fragment to the DOM
      const toastDocumentFragmentDiv = toastDocumentFragment.querySelector('.toast')
      toastDocumentFragmentDiv.id = this.toastId;
      this.toastContainer.append(toastDocumentFragmentDiv);

      // Get the DOM Node for Bootstrap to be accepted
      const toastNode = this.toastContainer.querySelector('#' + this.toastId);
      const toast = new Toast(toastNode, {

        animation: true,
        autohide: true,
        delay: 6000
      });

      toastNode.addEventListener("hidden.bs.toast", () => {
        console.debug("Toast: Request to hide", this.toastId,
          document.querySelector("#toasts"));
        toast.dispose();

        const toastNode = this.toastContainer.querySelector('#' + this.toastId);
        if (toastNode) {
          toastNode.remove();
        }

      });

      toast.show();
      this.element.remove();
    }, 0)
  }

  get toastContainer() {
    return document.getElementById('toasts');
  }

  disconnect() {
    console.debug("/ Toast disconnected", this.toastId);
  }
}

