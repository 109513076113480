import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="candidates--document-form"
export default class extends Controller {
  static targets = ["nameInput"]

  connect() {
    console.log("=> document form controller connected", this.nameInputTarget);
  }

  fileChanged(event) {
    const file = event.target.files[0];
    this.nameInputTarget.value = file.name;
  }
}
