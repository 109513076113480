import {Controller} from "@hotwired/stimulus"
// import PerfectScrollbar from 'perfect-scrollbar';

export default class extends Controller {
  connect() {
    console.debug("ADMIN CONNECTED");
    // feather.replace();

    // this.enablePerfectScrollbar();
  }

  disconnect() {
  }

  // enablePerfectScrollbar() {
  //   if (window.matchMedia('(max-width: 991px)').matches) {
  //     const psNavbar = new PerfectScrollbar('#navbarMenu', {
  //       suppressScrollX: true
  //     });
  //   }
  // }

  // This is only for horizontal menu
  setNavbarActive() {
    const navbar = document.querySelector('#navbarMenu .active');

    if (navbar == null) {
      console.warn("ADMIN: #navbarMenu .active does not exist");
      return;
    }

    if (window.matchMedia('(max-width: 991px)').matches) {
      navbar.classList.add('show');
    } else {
      navbar.classList.remove('show');
    }
  }
}
