import { Controller } from "@hotwired/stimulus"
import {Turbo} from "@hotwired/turbo-rails"
import {Offcanvas} from "bootstrap";

// Connects to data-controller="offcanvas"
export default class extends Controller {
  static values = {
    show: {type: Boolean, default: false }
  }
  static targets = ["focusInput"]

  initialize() {
    console.debug("Offcanvas initialized");

    this.offcanvas = new Offcanvas(this.element, {
      backdrop: "static",
      keyboard: false
    });

    if (this.showValue) {
      this.show();
    }

    if (this.hasFocusInputTarget) {
      this.element.addEventListener("shown.bs.offcanvas", () => {
        this.focusInputTarget.focus();
      }, {once: true});
    }

    this.element.addEventListener("hidden.bs.offcanvas", () => {
      // this.element.remove();
    }, {once: true});
  }

  connect() {
    console.debug("Offcanvas connected");
  }

  disconnect() {
    console.log("Offcanvas disconnected");
  }

  show() {
    this.offcanvas.show();
  }

  hide() {
    this.offcanvas.hide();
  }

  // linkClicked(event) {
  //   // event.preventDefault();
  //   this.hide();
  //
  //   // setTimeout(() => {
  //   //   console.log("linkClicked", event);
  //   //   // Turbo.visit(event.currentTarget.href, {action: "replace"});
  //   // }, 300);
  // }


  /**
   * Mean to be called when a turbo-submit-end event is triggered
   * @example
   * <%= form_with url: company_candidates_path,
   *     data: {action: "turbo:submit-end->offcanvas#hideIfFormSubmitSucceed"} do |form| %>
   * @param event
   */
  hideIfFormSubmitSucceed(event) {
    console.log("hideIfFormSubmitSucceed", event);
    if (event.detail.success) {
      this.hide();
    }
  }
  //
  // hideAndReloadIfFormSubmitSucceed(event) {
  //   if (event.detail.success) {
  //     this.element.addEventListener("hidden.bs.offcanvas", () => {
  //       Turbo.visit(location.href, {action: "advance"});
  //     }, {once: true});
  //
  //     this.hide();
  //   }
  // }
}
