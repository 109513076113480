import {Controller} from "@hotwired/stimulus"
import {Dropdown} from "bootstrap";

// Connects to data-controller="candidates--tags-form"
export default class extends Controller {
  static targets = []

  static values = {
    targetFieldId: String,
    inTrix: Boolean,
  }

  connect() {

    // This helps to initialize when turbo replace the dropdown
    if (this.toolbar) {
      console.log("MessagingPlaceholderDropdownController toolbar exists", this.toolbar);
      this.initializeTrix();
    }
  }

  insertPlaceholder(event) {
    event.preventDefault();

    const placeholder = event.params.placeholder;
    const targetField = this.targetField;

    // console.warn("Inserting placeholder", placeholder, "in", targetField);

    if (placeholder && targetField) {
      this.appendPlacedholderIntoField(targetField, placeholder.placeholder);
    }

    if (this.inTrixValue) {
      this.bsDropdown.hide();
    }
  }


  appendPlacedholderIntoField(field, placeholderText) {
    if (field.tagName === "TRIX-EDITOR") {
      field.editor.insertHTML("<code>" + placeholderText + "</code>");
      return;
    }

    field.value += placeholderText;
  }

  /**
   * Add a button to the Trix toolbar to open the placeholder dropdown
   * it should be called when the page is loaded or when the dropdown is replace by Turbo
   * @param event
   */
  initializeTrix(event) {
    console.debug("Initializing Trix", event);

    if (!(this.inTrixValue && this.targetField)) {
      console.debug("Not in Trix or target field not found", this.inTrixValue, this.targetFieldIdValue);
      return;
    }

    // Trix.config.blockAttributes.code = {
    //   tagName: "code",
    //   // style: { color: "var(--bs-code-color)", display: "inline-flex" },
    //   parse: false,
    //   nestable: false,
    //   exclusive: true,
    // };
    //
    // Trix.config.textAttributes.code = {
    //   tagName: "code",
    //   // style: { color: "var(--bs-code-color)", display: "inline-flex" },
    //   inheritable: true,
    //   parser: function (element) {
    //     // var style = window.getComputedStyle(element);
    //     // return style.textDecoration === "underline";
    //     debugger;
    //     return element.tagName === "CODE";
    //   },
    // };

    const trixEditor = this.targetField;
    const toolbar = document.getElementById(trixEditor.getAttribute("toolbar"));
    console.log("Trix editor", trixEditor, trixEditor.getAttribute("toolbar"), toolbar);

    const row = toolbar.querySelector(".trix-button-row");

    const button = document.createElement("button");
    button.classList.add("trix-button", "dropdown-toggle");
    button.setAttribute("type", "button");
    button.innerHTML = "Variables";
    button.onclick = this.openPlaceholderDropdown.bind(this);

    let div = row.querySelector(".trix-button-group--placeholder-tools")

    // Remove existing content in placeholder tools if it exists
    if (div) {
      div.innerHTML = "";
    } else {
      div = document.createElement("div");
      div.classList.add("trix-button-group", "trix-button-group--placeholder-tools");
    }

    this.toolbarButton = button;
    div.appendChild(button);

    row.appendChild(div);
  }

  openPlaceholderDropdown(event) {
    event.preventDefault();
    console.log("Opening placeholder dropdown", this.dropdownMenu);

    this.bsDropdown.toggle();
  }

  get toolbar() {
    const trixEditor = this.targetField;

    if (!trixEditor) {
      return;
    }

    return document.getElementById(trixEditor.getAttribute("toolbar"));
  }

  get bsDropdown() {
    return Dropdown.getOrCreateInstance(this.dropdownMenu, {
      reference: this.toolbarButton,
      autoClose: true,
    })
  }

  get dropdownMenu() {
    return this.element.querySelector(".dropdown-menu");
  }

  get targetField() {
    return document.getElementById(this.targetFieldIdValue);
  }

  disconnect() {
  }
}
