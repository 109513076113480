import {Controller} from "@hotwired/stimulus"
import {get, post} from "@rails/request.js";
import {Offcanvas} from "bootstrap";

export default class extends Controller {
  static targets = ["sendEmail"]
  static values = {
    url: String, jobApplicationIds: Array
  }

  connect() {
    console.log("EmailSelectTemplateController connected", this.jobApplicationIdsValue);
    // this.element[this.identifier] = this;
  }

  disconnect() {
  }

  formSubmitted(event) {
    console.log("formSubmitted", event);

    if (event.detail.success) {
      this.hideModal();

      if (this.sendEmailTarget.checked) {
        this.openEmailModal()
      } else {
        this.refreshTable();
      }
    }
  }

  openEmailModal() {
    const selectedApplications = this.selectedApplications;
    console.log("openEmailModal", selectedApplications);

    const urlParams = new URLSearchParams();

    selectedApplications.forEach(appId => {
      urlParams.append("job_application_ids[]", appId);
    });


    get(this.emailModalUrlValue, {
      query: urlParams,
      responseKind: "turbo-stream"
    });
    // const modal = new bootstrap.Modal(document.getElementById("emailModal"));
    // modal.show();
  }

  hideModal() {
    Offcanvas.getInstance(this.element).hide();
  }

  /**
   * This method things we are in the PIPELINE page, add url instead the current location if you
   * want to use it in another page
   */
  refreshTable() {
    const urlParams = new URLSearchParams(window.location.search);
    console.log("refreshTable", urlParams.toString());

    // + "?" + urlParams.toString()
    get(location.pathname, {
      query: urlParams,
      responseKind: "turbo-stream"
    }).then(response => {
      console.log("response", response);
    });
  }
}
