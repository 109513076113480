import {Controller} from "@hotwired/stimulus"
import {Turbo} from "@hotwired/turbo-rails"
import {Modal} from "bootstrap";

// Connects to data-controller="ui--modal"
export default class extends Controller {
  static values = {
    show: {type: Boolean, default: false}
  }
  static targets = ["focusInput"]

  connect() {
    console.debug("Modal connected");

    this.modal = new Modal(this.element, {
      backdrop: "static",
      keyboard: false
    });

    if (this.showValue) {
      this.show();
    }

    if (this.hasFocusInputTarget) {
      this.element.addEventListener("shown.bs.modal", () => {
        this.focusInputTarget.focus();
      }, {once: true});
    }

    this.element.addEventListener("shown.bs.modal", () => {
      this.element.dispatchEvent(new CustomEvent("modal:shown", {bubbles: true}));
    }, {once: true});

    this.element.addEventListener("hidden.bs.modal", () => {
      this.element.dispatchEvent(new CustomEvent("modal:hidden", {bubbles: true}));
      this.element.remove();
    }, {once: true});
  }

  disconnect() {
    console.debug("Modal disconnected");
    this.element.remove();
  }

  show() {
    this.modal.show();
  }

  hide() {
    this.modal.hide();
  }


  /**
   * Mean to be called when a turbo-submit-end event is triggered
   * @example
   * <%= form_with url: company_candidates_path,
   *     data: {action: "turbo:submit-end->modal#hideIfFormSubmitSucceed"} do |form| %>
   * @param event
   */
  hideIfFormSubmitSucceed(event) {
    if (event.detail.success) {
      this.hide();
    }
  }

  //
  // hideAndReloadIfFormSubmitSucceed(event) {
  //   if (event.detail.success) {
  //     this.element.addEventListener("hidden.bs.modal", () => {
  //       Turbo.visit(location.href, {action: "advance"});
  //     }, {once: true});
  //
  //     this.hide();
  //   }
  // }
}
