import {Controller} from "@hotwired/stimulus"
import {post} from "@rails/request.js";
import {Carousel} from "bootstrap";
import carousel from "bootstrap/js/src/carousel";

/**
 * Second step of the email batch creation process.
 * This controller is responsible for the preview of the email batch.
 * It has a carousel to navigate through the emails.
 *
 * @class EmailBatchPreviewFormController
 */
export default class EmailBatchPreviewFormController extends Controller {
  static targets = ["carouselItem", "carousel", "counter"]
  static values = {url: String}

  connect() {
    console.log("EmailBatchPreviewForm connected")
    // this.element[this.identifier] = this;

    this.carouselTarget.addEventListener("slide.bs.carousel", (event) => {
      console.log("slide.bs.carousel", event);
      this.updateCounter(event);
    }, false);

    this.carousel = Carousel.getOrCreateInstance(this.carouselTarget, {
      keyboard: false,
      touch: false,
    });
    console.log("carousel", this.carousel);

    this.updateCounter();
  }

  disconnect() {
    // this.carousel.dispose();
  }

  updateCounter(event) {
    if(!this.hasCounterTarget) {
      return;
    }

    const index = event == null ? 0 : event.to;
    const total = this.carousel._getItems().length;

    this.counterTarget.innerText = `${index + 1} de ${total}`;
  }

  showCc() {
    this.ccTarget.parentNode.classList.remove("d-none");
  }

  showBcc() {
    this.bccTarget.parentNode.classList.remove("d-none");
  }

  showSpinner() {
    this.spinnerTarget.classList.remove("hidden");
  }

  hideSpinner() {
    this.spinnerTarget.classList.add("hidden");
  }

  // selectTemplate(event) {
  //   console.log("selectTemplate", event.params);
  //
  //   const formParams = new FormData(this.formTarget);
  //   formParams.append("planner[email_template_id]", event.params.id);
  //
  //   this.showSpinner();
  //
  //   // + "?" + urlParams.toString()
  //   post(this.urlValue, {
  //     body: formParams,
  //     responseKind: "turbo-stream"
  //   }).then(response => {
  //     console.log("response", response);
  //     this.hideSpinner();
  //   }).catch(error => {
  //     console.error("error", error);
  //     this.hideSpinner();
  //
  //   });
  // }
}
