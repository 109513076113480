import {Controller} from "@hotwired/stimulus"
// import PerfectScrollbar from 'perfect-scrollbar';

export default class UiHeaderController extends Controller {
  static targets = ['inputSearch']

  connect() {
    console.debug("Header - connected");

    // this.enablePerfectScrollbar();
    this.setNavbarActive();
    this.initSubMenus();
    this.initGlobalEvents();
  }

  disconnect() {
    console.debug("Header - disconnected");
  }

  initSubMenus() {
    const navLinks = this.element.querySelectorAll('.nav-item.with-sub .nav-link');

    for (const navLink of navLinks) {
      navLink.addEventListener('click', (event) => {
        event.preventDefault();

        navLink.parentNode.classList.toggle('show');
        this.closeAllSubMenusExcept(navLink.parentNode);

        if (window.matchMedia('(max-width: 991px)').matches) {
          this.psNavbar.update();
        }
      });
    }
  }

  initGlobalEvents() {
    const backdrop = document.querySelector(".backdrop");

    if (backdrop == null) {
      throw `Missing <div class="backdrop"></div>. Please as the last child of the <body>`;
    }

    backdrop.addEventListener('click', (event) => {
      console.debug("Header - Close the Backdrop");
      this.closeNavBar(event);
    });
  }

  closeAllSubMenusExcept(node) {
    const navItems = this.element.querySelectorAll('.nav-item.with-sub');
    for (const navItem of navItems) {
      if (navItem !== node) {
        navItem.classList.remove('show');
      }
    }
  }

  enablePerfectScrollbar() {
    // if (window.matchMedia('(max-width: 991px)').matches) {
      this.psNavbar = new PerfectScrollbar('#navbarMenu', {
        suppressScrollX: true
      });
    // }
  }

  // This is only for horizontal menu
  setNavbarActive() {
    const navbar = document.querySelector('#navbarMenu .active');

    if (navbar == null) {
      console.warn("ADMIN: #navbarMenu .active does not exist");
      return;
    }

    if (window.matchMedia('(max-width: 991px)').matches) {
      navbar.classList.add('show');
    } else {
      navbar.classList.remove('show');
    }
  }

  closeNavBar(event) {
    event.preventDefault();

    document.body.classList.remove('navbar-nav-show');
  }

  showNavBar(event) {
    event.preventDefault();

    document.body.classList.add('navbar-nav-show');
  }

  showSidebar(event) {
    event.preventDefault();

    document.body.classList.add('sidebar-show');
  }

  hideSidebar(event) {
    event.preventDefault();

    document.body.classList.remove('sidebar-show');
  }

  showNavBarSearch(event) {
    event.preventDefault();

    this.element.querySelector('.navbar-search').classList.add('visible');
    document.querySelector('.backdrop').classList.add('show');

    setTimeout(() => {
      this.inputSearchTarget.focus();
    });
  }

  hideNavBarSearch(event) {
    event.preventDefault();

    this.element.querySelector('.navbar-search').classList.remove('visible');
    document.querySelector('.backdrop').classList.remove('show');
  }

  closeAllSubMenusOnDesktop(event) {
    event.stopPropagation();

    if (window.matchMedia('(min-width: 992px)').matches) {
      const navTarg = event.target.closest('.navbar-menu .nav-item');

      // If a nav item is not clicked, hide all nav items
      if (navTarg == null) {
        console.debug('Header - close all submenus on desktop');

        const navItems = this.element.querySelectorAll('.nav-item.with-sub');
        for (const navItem of navItems) {
          navItem.classList.remove('show');
        }
      }
    }
  }
}
