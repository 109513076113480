import {Controller} from "@hotwired/stimulus"
import TomSelect from "tom-select";
import {get} from "@rails/request.js";

export default class JobApplicationsTableController extends Controller {
  static targets = ["appCheckbox", "toolbar", "toolbarInfo", "toolbarButtons"]
  static values = {
    emailModalUrl: String,
    messageModalUrl: String
  }

  connect() {
    console.log("JobApplicationsTableController connected")
    // this.element[this.identifier] = this;
  }

  disconnect() {
  }

  checkboxClicked(event) {
    console.log("checkbox clicked", this.selectedApplications);

    this.updateToolbarInfo();

    if (this.selectedApplications.length > 0) {
      this.showBulkActions();
    } else {
      this.hideBulkActions();
    }
  }

  selectAll(event) {
    if (event.target.checked) {
      this.appCheckboxTargets.forEach(checkbox => checkbox.checked = true);
    } else {
      this.appCheckboxTargets.forEach(checkbox => checkbox.checked = false);
    }

    this.updateToolbarInfo();

    if (this.selectedApplications.length > 0) {
      this.showBulkActions();
    } else {
      this.hideBulkActions();
    }
  }

  get selectedApplications() {
    return this.appCheckboxTargets
      .filter(checkbox => checkbox.checked)
      .map(checkbox => checkbox.value);
  }

  updateToolbarInfo() {
    if (this.selectedApplications.length === 0) {
      this.toolbarInfoTarget.innerText = "Seleccionar todos";
      return;
    }

    this.toolbarInfoTarget.innerText = `${this.selectedApplications.length} seleccionados`;
  }

  showBulkActions() {
    this.toolbarButtonsTarget.classList.remove("d-none");
    this.toolbarButtonsTarget.classList.add("d-flex");
  }

  hideBulkActions() {
    this.toolbarButtonsTarget.classList.remove("d-flex");
    this.toolbarButtonsTarget.classList.add("d-none");
  }

  openEmailModal(event) {
    const selectedApplications = this.selectedApplications;
    console.log("openEmailModal", selectedApplications);

    const urlParams = new URLSearchParams();

    selectedApplications.forEach(appId => {
      urlParams.append("job_application_ids[]", appId);
    });


    get(this.emailModalUrlValue, {
      query: urlParams,
      responseKind: "turbo-stream"
    });
    // const modal = new bootstrap.Modal(document.getElementById("emailModal"));
    // modal.show();
  }

  openMessageModal(event) {
    const selectedApplications = this.selectedApplications;
    console.log("openMessageModal", selectedApplications);

    const urlParams = new URLSearchParams();

    selectedApplications.forEach(appId => {
      urlParams.append("job_application_ids[]", appId);
    });


    get(this.messageModalUrlValue, {
      query: urlParams,
      responseKind: "turbo-stream"
    });
    // const modal = new bootstrap.Modal(document.getElementById("emailModal"));
    // modal.show();
  }

  refreshTable() {
    const urlParams = new URLSearchParams(window.location.search);
    console.log("refreshTable", urlParams.toString());

    // + "?" + urlParams.toString()
    get(location.pathname, {
      query: urlParams,
      responseKind: "turbo-stream"
    }).then(response => {
      console.log("response", response);
    });
  }
}
