import {Controller} from "@hotwired/stimulus"
import TomSelect from "tom-select";
import {get} from "@rails/request.js";

// Connects to data-controller="job-application-form"
export default class extends Controller {
  static targets = ["candidateSelect", "jobSelect"]
  static values = {availableJobsUrl: String}

  connect() {
    console.debug("=> job application form controller connected");

    if (this.hasCandidateSelectTarget) {
      this.candidateTomSelect = this.candidateSelectTarget.tomselect
        || new TomSelect(this.candidateSelectTarget, {});

      this.candidateTomSelect.on("change", () => {
        this.jobTomSelect.setValue("");
        this.jobTomSelect.clearOptions();
        this.jobTomSelect.load("");
      });
    }

    this.jobTomSelect = this.jobSelectTarget.tomselect ||
      new TomSelect(this.jobSelectTarget, {
        valueField: 'id',
        labelField: 'title',
        searchField: 'title',
        create: false,
        preload: 'focus',
        load: (query, callback) => {
          const url = `${this.availableJobsUrlValue}?query=${query}&candidate_id=${this.candidateId}`;
          // console.log("=> job application form controller load", url, this.candidateTomSelect.getValue());

          get(url, {responseKind: "json"})
            .then(response => response.json)
            .then(data => {
              console.log("=> job application form controller load data", data)
              callback(data);
            })
            .catch(() => callback([]));
        },
        render: {
          item: (item, escape) => {
            return `
              <div class="d-flex gap-1">
                ${escape(item.title)} - 
                <span class="text-secondary">${escape(item.company?.name)}</span>
              </div>
            `;
          },
          option: (item, escape) => {
            return `
              <div>
                ${escape(item.title)}<br>
                <span class="text-secondary">${escape(item.company?.name)}</span>
              </div>
            `;
          }
        }
      });
  }

  get candidateId() {
    if (this.hasCandidateSelectTarget) {
      return this.candidateTomSelect.getValue();
    } else {
      return this.element["job_application[candidate_id]"]?.value;
    }
  }

  focusInput(event) {
    console.debug("=> job application form controller focusInput", event.target);
    this.candidateTomSelect?.focus();
  }
}
