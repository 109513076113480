import {Controller} from "@hotwired/stimulus"
import TomSelect from "tom-select"

// Connects to data-controller="candidates--tags-form"
export default class extends Controller {
  static targets = ["tagListInput"]

  connect() {
    this.tom = new TomSelect(
      this.tagListInputTarget,
      {
        create: true,
        createOnBlur: true,
        persist: true,
        maxItems: 25,
        delimiter: ',',
        plugins: ['remove_button'],
      }
    );

    this.tom.focus();
  }

  disconnect() {
    this.tom.destroy();
  }
}
