import {Controller} from "@hotwired/stimulus"
import TomSelect from "tom-select";
import {get} from "@rails/request.js";

export default class extends Controller {
  static targets = ["companySelect", "orderSelect"]
  static values = {availableOrdersUrl: String}

  connect() {
    console.debug("=> job form controller connected", this.availableOrdersUrlValue);

    this.companyTomSelect = this.companySelectTarget.tomselect
      || new TomSelect(this.companySelectTarget, {});

    this.companyTomSelect.on("change", () => {
      this.orderSelect.setValue("");
      this.orderSelect.clearOptions();
      this.orderSelect.load("");
    });

    this.orderSelect = this.orderSelectTarget.tomselect ||
      new TomSelect(this.orderSelectTarget, {
        valueField: 'id',
        labelField: 'order_number',
        searchField: 'order_number',
        create: false,
        preload: 'focus',
        load: (query, callback) => {
          const url = `${this.availableOrdersUrlValue}?query=${query}&company_id=${this.companyId}`;

          get(url, {responseKind: "json"})
            .then(response => response.json)
            .then(data => {
              console.log("=> job application form controller load data", data)
              callback(data);
            })
            .catch(() => callback([]));
        },
        render: {
          item: (item, escape) => {
            let startDateHTML = "";

            if (item.start_date) {
              startDateHTML = `<span class="text-secondary"> (${escape(item.start_date)})</span>`;
            }

            return `
              <div class="d-flex gap-1">
                ${escape(item.order_number)} 
                ${startDateHTML}
              </div>
            `;
          },
          option: (item, escape) => {
            let startDateHTML = "";

            if (item.start_date) {
              startDateHTML = `<span class="text-secondary">${escape(item.start_date)}</span>`;
            }

            return `
              <div>
                ${escape(item.order_number)} 
                <br>
                ${startDateHTML}
              </div>
            `;
          }
        }
      });
  }

  disconnect() {
    console.debug("=> job form controller disconnected");
    this.companyTomSelect.destroy()
    this.orderSelect.destroy();
  }

  get companyId() {
    return this.companyTomSelect.getValue();
  }

}
