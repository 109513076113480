import {Controller} from "@hotwired/stimulus"
import TomSelect from "tom-select"

// Connects to data-controller="candidates--tags-form"
export default class extends Controller {
  static targets = ["input"]
  static values = {
    maxItems: {type: Number, default: 25},
    create: {type: Boolean, default: true}
  }

  connect() {
    this.tom = new TomSelect(
      this.inputTarget,
      {
        create: this.createValue,
        createOnBlur: true,
        persist: false,
        maxItems: this.maxItemsValue,
        delimiter: ',',
        plugins: {
          'remove_button': {},
          'restore_on_backspace': {
            html: (option) => {
              console.warn("Restore", option);
              return option[self.settings.labelField];
            }
          }
        }
      }
    );

    const datalist = document.querySelector(
      "#" + this.inputTarget.getAttribute("list")
    );

    if (!datalist) {
      return;
    }

    for (const option of datalist.options) {
      this.tom.addOption({
        value: option.value,
        text: option.text
      });
    }
  }

  disconnect() {
    this.tom.destroy();
  }
}
