import {Controller} from "@hotwired/stimulus"
import {get} from "@rails/request.js";

export default class EmailTemplateFormController extends Controller {
  static targets = ["form", "spinner", "recordType"]
  static values = {availablePlaceholdersUrl: String}

  connect() {
  }

  disconnect() {
  }

  showSpinner() {
    this.spinnerTarget.classList.remove("hidden");
  }

  hideSpinner() {
    this.spinnerTarget.classList.add("hidden");
  }

  updatePlaceholders(event) {
    console.log("Updating placeholders", this.availablePlaceholdersUrlValue);

    // this.showSpinner();
    this.recordTypeTarget.setAttribute("disabled", "disabled")

    // + "?" + urlParams.toString()
    get(this.availablePlaceholdersUrlValue, {
      query: {
        record_type: this.recordTypeTarget.value
      },
      responseKind: "turbo-stream"
    }).then(response => {
      console.log("response", response);
      // this.hideSpinner();

      this.recordTypeTarget.removeAttribute("disabled")
    }).catch(error => {
      console.error("error", error);
      // this.hideSpinner();

      this.recordTypeTarget.removeAttribute("disabled")
    });
  }
}
