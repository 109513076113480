import {Controller} from "@hotwired/stimulus"
import {post} from "@rails/request.js";

export default class EmailBatchFormController extends Controller {
  static targets = ["form", "cc", "bcc"]
  static values = {url: String}

  connect() {
    console.log("EmailSelectTemplateController connected")
    // this.element[this.identifier] = this;
  }

  disconnect() {
  }

  showCc() {
    this.ccTarget.parentNode.classList.remove("d-none");
  }

  showBcc() {
    this.bccTarget.parentNode.classList.remove("d-none");
  }

  showSpinner() {
    this.spinnerTarget.classList.remove("hidden");
  }

  hideSpinner() {
    this.spinnerTarget.classList.add("hidden");
  }

  // selectTemplate(event) {
  //   console.log("selectTemplate", event.params);
  //
  //   const formParams = new FormData(this.formTarget);
  //   formParams.append("planner[email_template_id]", event.params.id);
  //
  //   this.showSpinner();
  //
  //   // + "?" + urlParams.toString()
  //   post(this.urlValue, {
  //     body: formParams,
  //     responseKind: "turbo-stream"
  //   }).then(response => {
  //     console.log("response", response);
  //     this.hideSpinner();
  //   }).catch(error => {
  //     console.error("error", error);
  //     this.hideSpinner();
  //
  //   });
  // }
}
