import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="auto-submit"
export default class AutoSubmit extends Controller {
  // delayValue: number

  static values = {
    delay: {
      type: Number,
      default: 150,
    },
    disableOnSubmit: {
      type: Boolean,
      default: false,
    },
  }

  initialize() {
    this.submit = this.submit.bind(this)
  }

  connect() {
    if (this.delayValue > 0) {
      this.submit = debounce(this.submit, this.delayValue)
    }
  }

  submit() {
    console.log("=> Submit", this.element);
    this.element.requestSubmit()
  }
}

function debounce(func, delay = 0) {
  let timeoutId;

  return function() {
    clearTimeout(timeoutId);

    timeoutId = setTimeout(() => {
      func.apply(this, arguments);
    }, delay);
  }
};