import {Controller} from "@hotwired/stimulus"
import {post, patch} from "@rails/request.js";

export default class EmailPlannerSelectTemplateController extends Controller {
  static targets = ["form", "spinner"]
  static values = {url: String}

  connect() {
    console.log("EmailSelectTemplateController connected")
    // this.element[this.identifier] = this;
  }

  disconnect() {
  }

  showSpinner() {
    this.spinnerTarget.classList.remove("hidden");
  }

  hideSpinner() {
    this.spinnerTarget.classList.add("hidden");
  }

  selectTemplate(event) {
    console.log("selectTemplate", event.params);

    const formParams = new FormData();
    formParams.append("email_template_id", event.params.id);

    this.showSpinner();

    // + "?" + urlParams.toString()
    patch(this.urlValue, {
      body: formParams,
      responseKind: "turbo-stream"
    }).then(response => {
      console.log("response", response);
      this.hideSpinner();
    }).catch(error => {
      console.error("error", error);
      this.hideSpinner();

    });
  }
}
