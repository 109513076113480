import {Collapse} from "bootstrap";


/**
 * Shows a collapsible element. Make sure the element has the "collapse" CSS class.
 *
 * @param {HTMLElement} element - The element to be shown.
 */
export function showCollapsible(element) {
  return new Promise(function (resolve) {
    const collapsible = new Collapse(element, {toggle: false});
    collapsible.show();

    element.addEventListener("shown.bs.collapse", function (event) {
      resolve();
      collapsible.dispose();
    }, {once: true});

  });
}


/**
 * Hides a collapsible element. Make sure the element has the "collapse" CSS class.
 *
 * @param {HTMLElement} element - The element to be shown.
 */
export function hideCollapsible(element) {
  return new Promise(function (resolve) {
    const collapsible = new Collapse(element, {toggle: false});
    collapsible.hide();

    element.addEventListener("hidden.bs.collapse", function (event) {
      resolve();
      collapsible.dispose();
    }, {once: true});
  });
}
