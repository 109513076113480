import {Controller} from "@hotwired/stimulus"

// Connects to data-controller="accounts--company-form"
export default class extends Controller {
  static targets = ["parentIdGroup", "addressSection"]

  connect() {
  }

  setParentIdVisibility(event) {
    console.log(event);
    if (event.params.companyType === "company") {
      this.parentIdGroupTarget.classList.remove("d-none")
      this.parentIdGroupTarget.querySelector("select").focus();

      this.addressSectionTarget.classList.remove("d-none")
    } else {
      this.addressSectionTarget.classList.add("d-none")
    }
  }
}
