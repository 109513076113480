import {Controller} from "@hotwired/stimulus"
import {get} from "@rails/request.js";

// Connects to data-controller="candidates--tags-form"
export default class extends Controller {
  static targets = ["subjectInput", "headlineTrix"]
  static values = {
    previewUrl: String
  }

  connect() {
    console.log("Connect", this.subjectInputTarget, this.headlineInputTarget);
  }

  disconnect() {
  }

  restoreLine(event) {
    const container = event.target.parentNode;
    const input = container.querySelector("input[type=hidden]");
    const trixEditor = container.querySelector("trix-editor");
    const template = container.querySelector("template");

    // console.log("Restore line", input, trixEditor, template);

    const templateContent = template.cloneNode(true).content;

    const tempDiv = document.createElement("div");
    tempDiv.append(templateContent);

    const previousContent = tempDiv.querySelector(".trix-content").innerHTML.trim();
    // console.log("Previous content", previousContent);

    trixEditor.value = previousContent;
    input.value = previousContent;
  }

  // preview(event) {
  //   event.target.disabled = true;
  //
  //   const params = {
  //     subject: this.subject,
  //     headline: this.headline
  //   };
  //
  //   console.log("Preview", event.params, params, this.previewUrlValue);
  //
  //   get(this.previewUrlValue, {
  //     query: params,
  //     kind: "turbo-stream",
  //   }).then(response => {
  //     event.target.disabled = false;
  //     console.log("Response", response);
  //   }).catch(error => {
  //     event.target.disabled = false;
  //     console.error("Error", error);
  //   });
  // }

  get subject() {
    return this.subjectInputTarget.value;
  }

  get headline() {
    return this.headlineTrixTarget.value;
  }


}

